import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'itsmarty-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  status = false;

  constructor() { }

  ngOnInit() {

  }

  hoverEvent() {
      this.status = !this.status;
  }
}
