<section id="contact" class="contact" data-url="contact"   inViewport [inViewportOptions]="{ threshold: 1 }" (inViewportAction)="onIntersection($event)">
    <div class="container-fluid no-padd">
      <div class="row no-gutters">
        <div class="col-12 col-md-6">
            <div class="contact__image">

            </div>
        </div>
        <div class="col-12 col-md-6 contact__right">
            <div class="contact__form">
              <h4 class="sub-title sub-title--white text-center">We helpen u graag</h4>
              <h3 class="title title--white text-center">Contact opnemen</h3>
              <p class="alert alert-success" [ngClass]="submitStatus ? '' : 'd-none'">Bedankt voor het invullen, we nemen zo spoedig mogelijk contact met u op.</p>
              <p class="alert alert-danger" [ngClass]="error ? '' : 'd-none'">Er is iets verkeerd gegaan met het versturen van het formulier, probeert u het nog eens of bel 0187-601619.</p>
              <form #contactForm="ngForm" (ngSubmit)="submit(contactForm)" [ngClass]="submitStatus ? 'd-none' : ''">

                <div class="form-group">
                  <input type="text" class="form-control" name="name" ngModel name="firstName" #firstName="ngModel" required placeholder="Naam">
                  <span class="error" *ngIf="firstName.touched && !firstName.valid">Uw naam is verplicht.</span>
                </div>

                <div class="form-group">
                    <input type="email" class="form-control" email name="email" ngModel #email="ngModel" [required]="!phone.valid" placeholder="E-mailadres">
                    <span class="error" *ngIf="(email.touched && !email.valid) && (!phone.valid)">Uw telefoonnummer of e-mailadres is verplicht.</span>
                </div>

                <div class="form-group">
                    <input type="text" class="form-control" name="phone" ngModel #phone="ngModel" [required]="!email.valid" placeholder="Telefoonnummer">
                    <span class="error" *ngIf="(phone.touched && !phone.valid) && (!email.valid) ">Uw telefoonnummer of e-mailadres is verplicht.</span>
                </div>

                <div class="form-group">
                    <textarea class="form-control" rows="5" name="message" ngModel  #message="ngModel" required placeholder="Uw Bericht"></textarea>
                    <span class="error" *ngIf="message.touched && !message.valid">Uw bericht is verplicht.</span>
                </div>
              
                <div class="form-group text-center">
                  <button class="btn btn-rounded" [disabled]="!contactForm.valid" type="submit">Verzenden</button>
                </div>

              </form>
            </div>
        </div>
      </div>
    </div>
  </section>
  