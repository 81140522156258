import { Component, OnInit, HostBinding } from '@angular/core';

@Component({
  selector: 'itsmarty-holiday-modal',
  templateUrl: './holiday-modal.component.html',
  styleUrls: ['./holiday-modal.component.scss']
})
export class HolidayModalComponent implements OnInit {
  @HostBinding('class.showModal') showModal = false;

  constructor() { }

  ngOnInit() {
    if (new Date() > new Date('2024-08-12')) {
      this.showModal = false;
    } else if (new Date() >= new Date('2024-07-27')) {
      this.showModal = true;
    }
  }

  close() {
    this.showModal = false;
  }

}
