import { Component, OnInit } from '@angular/core';
import { PushStateService } from '../services/push-state.service';

@Component({
  selector: 'itsmarty-workshop',
  templateUrl: './workshop.component.html',
  styleUrls: ['./workshop.component.scss']
})
export class WorkshopComponent implements OnInit {

  constructor(private pushState: PushStateService) { }

  ngOnInit() {
  }

  onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.pushState.onIntersection({target, visible} );
  }


}
