<div role="document" class="modal-dialog">
  <div class="modal-content">
    <div class="modal-header">
      <h4 id="modal-basic-title" class="modal-title">Vakantie</h4>
      <button type="button" aria-label="Close" class="close close-holiday" (click)="close()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      <p> Wij zijn met vakantie van 29 juli tot en met 10 augustus. Maandag 12 augustus zijn we weer terug met lekkere
        producten en leuke acties.</p>
    </div>
  </div>
</div>
