<section class="hero" data-url='' inViewport [inViewportOptions]="{ threshold: 1 }"
  (inViewportAction)="onIntersection($event)">
  <div class="hero__image">
    <div class="hero__bg">
    </div>
  </div>
  <div class="hero__content">
    <div class="container h-100">
      <div class="row align-items-center text-center h-100">
        <div class="col-12">
          <h1 class="hero__title">Iedere keer weer een traktatie!
          </h1>
          <p class="lead  hero__sub-title">DÉ AMBACHTELIJKE BAKKER</p>
          <p class="lead">
            <button (click)="toDiv('welkom')" class="btn btn-rounded" role="button">Lees meer</button>
            <a class="btn btn-black btn-rounded" style="margin: 16px;" target="_blank"
              href="assets/productenlijst-bakkerij-van-Harberden-februari-2025.pdf">Productenlijst</a>
          </p>

        </div>
      </div>
    </div>
  </div>
  <div class="hero__bottom">
    <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px" viewBox="0 0 1400 150" style="enable-background:new 0 0 1400 150;" xml:space="preserve">
      <style type="text/css">
        .st0 {
          fill: #FFFFFF;
        }
      </style>
      <title>Mask</title>
      <g>
        <path class="st0" d="M0,150h700C466.7,150,233.3,100,0,0V150z" />
        <path class="st0" d="M700,150h700V0C1166.7,100,933.3,150,700,150z" />
      </g>
    </svg>

  </div>
</section>
