<nav class="navbar">
  <div class="container ">
    <div class="col-3 col-sm-4 col-md-4">
      <div class="opening-hours" (click)="hoverEvent()" [ngClass]="status ? 'hover' : ''">
        <span class="white opening-hours__label">Openingstijden</span>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-down"
          class="svg-inline--fa fa-chevron-down fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512">
          <path fill="currentColor"
            d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z">
          </path>
        </svg>
        <div class="opening-hours__popup" aria-haspopup="true">
          <div><span class="opening-hours__day">MA</span><span class="opening-hours__time">07:30 - 18:00</span></div>
          <div><span class="opening-hours__day">DI</span><span class="opening-hours__time">07:30 - 13:00</span></div>
          <div><span class="opening-hours__day">WO</span><span class="opening-hours__time">07:30 - 18:00</span></div>
          <div><span class="opening-hours__day">DO</span><span class="opening-hours__time">07:30 - 18:00</span></div>
          <div><span class="opening-hours__day">VR</span><span class="opening-hours__time">07:30 - 18:00</span></div>
          <div><span class="opening-hours__day">ZA</span><span class="opening-hours__time">07:00 - 16:30</span></div>
          <div><span class="opening-hours__day">ZO</span><span class="opening-hours__time">Gesloten</span></div>
        </div>
      </div>
    </div>
    <div class="col-6 col-sm-4 text-center">
      <img alt="Bakkerij van harberden" src="assets/logo.svg" class="d-inline-block img-fluid">
    </div>
    <div class="offset-1 offset-sm-0 col-1 col-sm-4 col-md-4">
      <a class="white phone-number " href="tel:0187601619" title="0187-601619">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone"
          class="svg-inline--fa fa-phone fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="currentColor"
            d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z">
          </path>
        </svg>
        <span class="d-none d-md-inline-block">0187-601619</span>
      </a>
    </div>
  </div>
</nav>