import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'itsmarty-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = 'Bakkerij van Harberden';

  ngAfterViewInit() {
    this.scrollToDiv();

  }

  scrollToDiv()
  {
    const pathArray = window.location.pathname.split( '/' );
    const segment = pathArray[1];
    if(segment.length > 0) {
      document.querySelector('[data-url=' + segment + ']').scrollIntoView({
        behavior: 'smooth'
      });
    }
  }

}
