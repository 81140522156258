import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PushStateService {
  pushState = false;

  constructor() {
  }


  public onIntersection({ target, visible }: { target: Element; visible: boolean; }): void {
    if (visible) {
      this.pushState = true;
      history.pushState(null, target.id , target.getAttribute('data-url'));
    }
  }
}
