<footer class="footer">
  <section>
    <div class="container">
      <div class="row text-center text-md-left">
        <div class="col-12 col-md-3"><img class="img-fluid mb-3" width=200 src="assets/logo.svg"></div>
        <div class="col-12 col-md-3">
          <div class="footer__content">
            <h3 class="font__secondary">Bezoekadres</h3>
            <p>Julianaweg 30</p>
            <p>3248AN Melissant</p>
            <p><a class="route"
                href="https://www.google.nl/maps/dir//Bakkerij+van+Harberden,+Julianaweg+30,+3248+AN+Melissant/@51.7716885,4.0672748,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c45a729e9fdc81:0x3cdc551d814a8c4d!2m2!1d4.0694635!2d51.7716852!3e0"
                target="_blank">Routebeschrijving</a></p>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="footer__content footer__content--contact">
            <h3 class="font__secondary">
              Contact
            </h3>
            <p><span>Tel.</span> 0187-601619</p>
            <p><span>KVK.</span> 23037477</p>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="footer__content">
            <h3 class="font__secondary">
              Openingstijden
            </h3>
            <div class="opening-hours">
              <div><span class="opening-hours__day">MA</span><span class="opening-hours__time">07:30 - 18:00</span>
              </div>
              <div><span class="opening-hours__day">DI</span><span class="opening-hours__time">07:30 - 13:00</span>
              </div>
              <div><span class="opening-hours__day">WO</span><span class="opening-hours__time">07:30 - 18:00</span>
              </div>
              <div><span class="opening-hours__day">DO</span><span class="opening-hours__time">07:30 - 18:00</span>
              </div>
              <div><span class="opening-hours__day">VR</span><span class="opening-hours__time">07:30 - 18:00</span>
              </div>
              <div><span class="opening-hours__day">ZA</span><span class="opening-hours__time">07:00 - 16:30</span>
              </div>
              <div><span class="opening-hours__day">ZO</span><span class="opening-hours__time">Gesloten</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <img src="assets/images/footer.png"> -->
  <picture>
    <source srcset="assets/images/footer.webp" type="image/webp">
    <source srcset="assets/images/footer.jp2" type="image/jp2">
    <img src="assets/images/footer.png" alt="Brood" class="img-fluid footer-img">
  </picture>
</footer>