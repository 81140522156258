import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders} from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class FormService {
  baseurl = 'https://mailing.bakkerijvanharberden.nl';

  constructor(private http: HttpClient) { }

  submit(model: any){
  const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Max-Age': '600',
      })
    };

  return this.http.post<any>(this.baseurl, model)
    .pipe(
      map((response: any) => {
        console.log(response);
      })
    );
  }

}
