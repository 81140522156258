import { Component, OnInit } from '@angular/core';
import { PushStateService } from '../services/push-state.service';

@Component({
  selector: 'itsmarty-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit {

  constructor(private pushState: PushStateService) { }

  ngOnInit() {
  }

  onIntersection({ target, visible }: { target: Element; visible: boolean }) : void {
    this.pushState.onIntersection({target, visible} );
  }

  toDiv(segment) { 
    document.getElementById(segment).scrollIntoView({
      behavior: 'smooth'
    });
  }

}
