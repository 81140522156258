
<section id="receipt" class="receipt" data-url="receptenboek"  inViewport [inViewportOptions]="{ threshold: 1 }" (inViewportAction)="onIntersection($event)">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-lg-6">
            <h4 class="sub-title">Flakkees receptenboek</h4>
            <h3 class="title">Nog meer lekker Flakkees</h3>
            <p>Het eiland Goerree-Overflakkee kent, naast de klassiekers als het kruukplaetje, de Flakkeese bolus en advocaat een groot aantal producten die karakteristiek zijn voor het eiland:
              bijvoorbeeld een broodje met Goereese port of het Stellendamse vistaartje. In <i> Nog meer lekker Flakkees</i> rijdt bakker Jan van Harberden met zijn oude transportfiets over het eiland, op zoek naar de beste grondstoffen en de mooiste producten.
            </p>
            <p>
              Jan geeft in dit boekje de geheimen prijs van bekende lekkernijen zoals de boluskoekjes, koffiekokertjes en appelbeignets, maar ook de recepten van de nieuwe producten zoals het Bommelse beertje, het duindoorngebakje en het marsepeinkasteeltje.
            </p>
        </div>
        <div class="col-12 col-lg-6">
          <div class="receipt__image text-center">
              <picture>
                  <source srcset="assets/images/lekker-flakkees.jpg" type="image/webp">
                  <source srcset="assets/images/lekker-flakkees.jp2" type="image/jp2"> 
                  <img src="assets/images/lekker-flakkees.jpg" alt="flakkees receptenboek" class='img-fluid'> 
                </picture>
          </div>
        </div>
      </div>
    </div>
  </section>
  