
<section id="workshop" class="workshop" data-url="workshops"  inViewport [inViewportOptions]="{ threshold: 1 }" (inViewportAction)="onIntersection($event)">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-12 col-lg-6 order-6 order-lg-1">
            <div class="row">
              <div class="col-12 col-sm-6">
                  <img src="assets/images/brood.jpg" class='img-fluid d-none d-sm-block'>
              </div>
              <div class="col-12 col-sm-6">
                  <img src="assets/images/jan_en_heleen.jpg" class='img-fluid'>
              </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 order-1 order-lg-6">
          <div class="workshop__content">
              <h4 class="sub-title">Professionele thuisbakkers</h4>
              <h3 class="title ">Onze workshops</h3>
              <p>Voor professionele thuisbakkers geeft bakker Jan van Harberden een bijzondere manier van ondersteuning door middel van het geven van verschillende workshops. In deze workshops kun je alle kneepjes van het bakkersvak leren. Er zijn verschillende mogelijkheden, bijvoorbeeld onze bolusworkshop waarin je 20 heerlijke bolussen maakt. Een workshop is mogelijk vanaf 20 personen.</p>

              <p><strong>Neem telefonisch contact op voor de workshop.</strong></p>
              <a href="tel:0187601619" class="btn btn-rounded" title="0187-601619" role="button">
                  <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="phone" class="svg-inline--fa fa-phone fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path>
                  </svg>
                0187-601619</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  