
<section id="tip" class="tip" data-url="tip-van-jan"  inViewport [inViewportOptions]="{ threshold: 1 }" (inViewportAction)="onIntersection($event)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6">
          <h4 class="sub-title sub-title--white">Zelf aan de slag met</h4>
          <h3 class="title title--white">De tip van Jan</h3>
          <p class="white">Snij enkele van onze heerlijke eierkoeken open, besprenkel de eierkoeken met een likeurtje die je lekker vindt. 
            Spuit een flinke toef slagroom tussen de eierkoeken en decoreer ze met wat poedersuiker.<br><br> <strong>Eet smakelijk!</strong>
      
          </p>
      </div>
      <div class="col-12 col-lg-6">
        <div class="tip__image">
          <picture>
              <source srcset="assets/images/jan.webp" type="image/webp">
              <source srcset="assets/images/jan.jp2" type="image/jp2"> 
                <img src="assets/images/jan.png" alt="Jan van Harberden" class="img-fluid">  
            </picture>
        </div>
      </div>
    </div>
  </div>
</section>
