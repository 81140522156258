
<section class="intro"  data-url="welkom" id="welkom"  inViewport [inViewportOptions]="{ threshold: 1 }" (inViewportAction)="onIntersection($event)">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-5">
        <div class="intro__left">
          <h4 class="sub-title">Welkom bij</h4>
          <h3 class="title">van harberden</h3>
          <p class="mt-4 mb-4">Bakkerij van Harberden is een ambachtelijke bakkerij waar de producten nog echt achter de winkel worden gebakken in onze eigen ovens. Heerlijkheden zoals gevulde bolussen, worstenbroodjes met spek, appelrondo's, verrukkelijk brood en prachtige taarten.</p>
          
          <picture>
            <source srcset="assets/images/aardbeientaart.webp" type="image/webp">
            <source srcset="assets/images/aardbeientaart.jp2" type="image/jp2"> 
            <img src='assets/images/aardbeientaart.jpg' class="img-fluid d-none d-md-block" alt="Een heerlijke aardbeien taart">
          </picture>
        </div>
      </div>
      <div class="col-12 col-md-6 offset-md-1">
        <div class="intro__right">
            <picture>
                <source srcset="assets/images/bakkerij.webp" type="image/webp">
                <source srcset="assets/images/bakkerij.jp2" type="image/jp2"> 
                  <img src='assets/images/bakkerij.jpg' class="img-fluid" alt="De prachtige bakkerij van bakkerij van Harberden">
              </picture>
            <p class="mt-4 mb-4">Elke dag zijn de bakkers weer vol enthousiasme in de weer om de heerlijkste lekkernijen te maken die door onze winkeldames met liefde aan u worden verkocht. We zien u graag in onze winkel!</p>
            <span class="font__secondary personal">Familie van Harberden</span>
        </div>
      </div>
    </div>
  </div>
  <img src="assets/graantje.svg" alt="graan" class="d-none d-md-block intro-graan">
</section>
