<div class="site">
  <header class="header">
    <itsmarty-nav></itsmarty-nav>
  </header>
  <div class="main">
    <div class="main__inner">
      <itsmarty-hero></itsmarty-hero>
      <itsmarty-intro></itsmarty-intro>
      <itsmarty-tip></itsmarty-tip>
      <itsmarty-receiptbook></itsmarty-receiptbook>
      <itsmarty-contact></itsmarty-contact>
      <!-- <itsmarty-holiday-modal></itsmarty-holiday-modal> -->
    </div>
  </div>

  <itsmarty-footer></itsmarty-footer>
</div>
