import { NgModule, OnInit } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute, Router } from '@angular/router';
import { IntroComponent } from './intro/intro.component'
import { AppComponent } from './app.component';
import { TipComponent } from './tip/tip.component';
import { WorkshopComponent } from './workshop/workshop.component';
import { ReceiptbookComponent } from './receiptbook/receiptbook.component';
import { ContactComponent } from './contact/contact.component';

const routes: Routes = [
  { path: 'welkom', component: IntroComponent },
  { path: 'tip-van-jan', component: TipComponent },
  { path: 'workshops', component: WorkshopComponent },
  { path: 'receptenboek', component: ReceiptbookComponent },
  { path: 'contact', component: ContactComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
  }
  
}
