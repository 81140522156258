import { Component, OnInit } from '@angular/core';
import { PushStateService } from '../services/push-state.service';

@Component({
  selector: 'itsmarty-receiptbook',
  templateUrl: './receiptbook.component.html',
  styleUrls: ['./receiptbook.component.scss']
})
export class ReceiptbookComponent implements OnInit {

  constructor(private pushState: PushStateService) { }

  ngOnInit() {
  }

  onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.pushState.onIntersection({target, visible} );
  }

}
