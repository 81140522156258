import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { HeroComponent } from './hero/hero.component';
import { IntroComponent } from './intro/intro.component';
import { InViewportModule } from 'ng-in-viewport';
import { TipComponent } from './tip/tip.component';
import { FooterComponent } from './footer/footer.component';
import { WorkshopComponent } from './workshop/workshop.component';
import { ReceiptbookComponent } from './receiptbook/receiptbook.component';
import { ContactComponent } from './contact/contact.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HolidayModalComponent } from './holiday-modal/holiday-modal.component';

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HeroComponent,
    IntroComponent,
    TipComponent,
    FooterComponent,
    WorkshopComponent,
    ReceiptbookComponent,
    ContactComponent,
    HolidayModalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    InViewportModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
