import { Component, OnInit, Renderer2 } from '@angular/core';
import { PushStateService } from '../services/push-state.service';

@Component({
  selector: 'itsmarty-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  
  constructor(private pushState: PushStateService) { }

  ngOnInit() {
  }

  onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.pushState.onIntersection({target, visible} );
  }

}

