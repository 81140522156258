import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PushStateService } from '../services/push-state.service';
import { NgForm } from '@angular/forms';
import { FormService } from '../services/form.service';

@Component({
  selector: 'itsmarty-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  model: any = {};
  submitStatus = false;
  error = false;
  

  constructor(private pushState: PushStateService, private formService: FormService, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  onIntersection({ target, visible }: { target: Element; visible: boolean }): void {
    this.pushState.onIntersection({ target, visible });
  }

  submit(contactForm: NgForm) {
    this.formService.submit(contactForm.value).subscribe(() => {
      this.error = false;
      this.submitStatus = true;
    }, error => {
      this.error = true;
    });
  }

}
